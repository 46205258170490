import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const FeaturedLink = ({ alt, children, heading, image }) => {
  return(
    <div className="flex flex-col md:flex-row md:even:flex-row-reverse space-x-4 my-4">
      <GatsbyImage className="w-full md:w-1/2" image={image} alt={alt} />
      <div className="self-center w-full md:w-1/2">
        <h2 className="text-4xl py-4">{heading}</h2>
        {children}
      </div>
    </div>
  )
}

export default FeaturedLink